<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <div class="page-title text-center px-5">
      <div
        v-if="authStatus"
      >
        <h2 class="text-2xl font-weight-semibold text--primary">
          🚀 👏🏻 授权成功 👏🏻 🚀
        </h2>
        <p class="text-sm">
          稍后会自动关闭页面!
        </p>
      </div>
      <div
        v-else
      >
        <h2 class="text-2xl font-weight-semibold text--primary">
          💣 😭 授权失败 😭 💣
        </h2>
        <p class="text-sm">
          请稍后重试！
        </p>
      </div>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/launching-soon.png"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { reactive, ref } from '@vue/composition-api'
import { toastSuccess } from '@core/utils/prompt'
import { requestGet } from '@core/utils/form'

export default {
  name: 'CallbackGoogle',
  setup() {
    const { route } = useRouter()
    const authStatus = ref(true)
    const submitData = reactive({
      code: '',
      state: '',
    })
    submitData.code = route.value.query?.code
    submitData.state = route.value.query?.state

    requestGet('/callback/getAccessToken', submitData).then(res => {
      toastSuccess(res.message || '授权成功！')
      localStorage.setItem('channelAuthed', 'authed')
      window.parent.postMessage({ authStatus: true }, '*');
      setTimeout(() => {
        window.close();
      }, 1000)
    }).catch(e => {
      setTimeout(() => {
        window.close();
        window.parent.postMessage({ authStatus: false }, '*');
      }, 3000)
      localStorage.setItem('channelAuthed', 'authed')
      authStatus.value = false
    })

    return {
      authStatus,
    }
  },
}
</script>

<style scoped>

</style>
